/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:2232510f-72e9-4cf6-b68f-0a52fc11266d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_cJERECtBU",
    "aws_user_pools_web_client_id": "lphnec1d2f5jhkc3n12o3npo7",
    "oauth": {}
};


export default awsmobile;
